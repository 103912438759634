export enum FileContentType {
    PDF = "PDF"
}


export class FileDownloads {
    displayFile(fileData: Blob, fileName: string, contentType: FileContentType) {
        if(window && (window as any as {ReactNativeWebView: any}).ReactNativeWebView) {
            const reader = new FileReader();
            reader.readAsDataURL(fileData); 
            reader.onloadend = function() {
                const base64data = reader.result;
                const message = {
                    type: 'fileData',
                    contentType: contentType,
                    base64Data: base64data,
                    fileName: fileName
                };
                (window as any as {ReactNativeWebView: any}).ReactNativeWebView.postMessage(JSON.stringify(message));
            }

        } else {
            const file = window.URL.createObjectURL(fileData);
            window.open(file, "_new");
        }
    }
}