import { useContext, useEffect, useState } from "react";
import trsLogo from "../assets/trs_logo.svg";
import "./MobileTRSHeader.scss";
import LoginModal from "../login-modal/LoginModal";
import UserMenu from "./UserMenu";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import UserMenuModal from "./UserMenuModal";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { useNavigate } from "react-router-dom";

type Props = {
    setShowUserMenuModal: (setVisible: boolean) => void;
    showUserMenuModal: boolean;
    applicationComplete: boolean;
    showStartApplicationLink?: boolean;
};

export default function MobileTRSHeader(props: Props) {
    const navigate = useNavigate()
    const [showLoginModalVisibility, setShowLoginModalVisibility] = useState(false);
    const [showStartApplicationLink, setShowStartApplicationLink] = useState(props.showStartApplicationLink ?? false);
    const userInfoModel = useContext(UserInfoModel);
    const [headerClass, setHeaderClass] = useState("");

    const showDialog = () => {
        setShowLoginModalVisibility(true);
    };

    useEffect(() => {
        if (userInfoModel.token !== "" && userInfoModel.userInfo.firstName !== "") { //checking first name so the start application link is hidden until the userInfo call comes back
            getCompletedSteps()
        }
    }, [navigate, userInfoModel.token, userInfoModel.userInfo]);

    const getCompletedSteps = async () => {
        const steps = await TPCoreAPI.getCompletedHCPApplicationSteps();
        setShowStartApplicationLink(!userInfoModel.userInfo.applicationComplete && steps.length === 0)
    }

    useEffect( () =>{
        switch(process.env.REACT_APP_ENV){
            case "DEVELOPMENT":
                setHeaderClass("development-header");
                break;
            case "TEST":
                setHeaderClass("test-header");
                break;
            case "PRODUCTION":
                setHeaderClass("");
                break;
            case "LOCAL":
                setHeaderClass("development-header");
                break;
            default:
                setHeaderClass("");
                break;
        }
    }, [])

    return (
        <div id="MobileTRSHeader">
            <LoginModal
                isVisible={showLoginModalVisibility}
                setVisibility={(value: boolean) => setShowLoginModalVisibility(value)}
                isLoginForm={true}
                successfulLogin={() => new Promise<void>(() => setShowLoginModalVisibility(false))}
                headerText="Log into your account or sign up below"
                canBeDismissed={true}
            />
            <div data-testid="mobile-header" className="mobile-header">
                <a data-testid="trs-logo" href={`${process.env.REACT_APP_HOMEPAGE_URL}`}>
                    <img src={trsLogo} alt="TRS Logo" />
                </a>
                <div className={headerClass} data-testid="env-header">
                    &nbsp;
                </div>
                {userInfoModel.token !== "" && (
                    <UserMenu
                        showLoginDialog={showDialog}
                        setShowMenuModal={props.setShowUserMenuModal}
                        showUserMenuModal={props.showUserMenuModal}
                    />
                )}
                {userInfoModel.token === "" && (
                    <div className="mobile-header-login-button" onClick={()=>setShowLoginModalVisibility(true)}>
                        Login
                    </div>
                )}
            </div>
            {props.showUserMenuModal && <UserMenuModal showStartApplicationLink={showStartApplicationLink} applicationComplete={props.applicationComplete} setShowUserMenuModal={props.setShowUserMenuModal} />}
        </div>
    );
}
