import { ReactNode, useEffect, useState } from "react";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { Session } from "../Session";
import UserInfoContext, { UserInfo } from "../../tp-core-types/UserInfoModel";


function UserInfoContextProvider({children} : {children : ReactNode}) {
    const [token, setToken] = useState<string>(Session.getHcpLoginToken())
    const defaultUserInfo = {
        firstName: "",
        lastName: "",
        applicationComplete: false,
        eligibleForSubmission: false,
        discipline: {id: 0, isAllied:false, name: "", webOrder: 1},
    };
    const [userInfo, setUserInfo] = useState<UserInfo>(defaultUserInfo)

    const [callbacks, setCallbacks] = useState<(() => void)[]>([])

    const getAndSetToken = () =>{
        setToken(Session.getHcpLoginToken());
    }

    useEffect(()=>{
        const token = Session.getHcpLoginToken();

        if (token){
            TPCoreAPI.getUserInfo().then((userInfo: UserInfo)=>{
                setUserInfo(userInfo)
            })   
        } else if (userInfo !== defaultUserInfo) { 
            setUserInfo(defaultUserInfo)
            callbacks.forEach(callback => {
                callback()
            });
        } 
    }, [token])

    const subscribeForLogout = (callback: any) => {
        callbacks.push(callback)
    }

    const unsubscribeForLogout = (callback: any) => {
        const indexOfCallback = callbacks.indexOf(callback)
        callbacks.splice(indexOfCallback, 1)
    }

    const refreshUserInfo = () => {
        const token = Session.getHcpLoginToken();
        if (token){
            TPCoreAPI.getUserInfo().then((userInfo: UserInfo)=>{
                setUserInfo(userInfo);
            })   
        } else if (userInfo !== defaultUserInfo) { 
            setUserInfo(defaultUserInfo)
            callbacks.forEach(callback => {
                callback()
            });
        } 
    }



    return(
        <UserInfoContext.Provider 
            value={{userInfo: userInfo, token:token, getAndSetToken: getAndSetToken, subscribeForLogout:subscribeForLogout, unsubscribeForLogout: unsubscribeForLogout, refreshUserInfo:refreshUserInfo}}>
            {children}
        </UserInfoContext.Provider>
    )
}

export default UserInfoContextProvider;