import AddToFavoriteButton from "../add-to-favorite-button/AddToFavoriteButton";
import StartApplicationButton from "../start-application/start-application-button/StartApplicationButton";
import { JobDetailsModel } from "../tp-core-types/JobDetailsModel";
import displayAsMoney from "../utilities/displayAsMoney";
import isArchivedJob from "../utilities/isArchivedJob";
import flame_icon from "../assets/flame.png";
import "./JobAltMainHeader.css";

type Props = {
  jobDetailsModel:JobDetailsModel,
  showLoginDialog: (afterLogin: () => Promise<void>) => void, 
  showStartApplicationModal: () => void,
  postFavoriteChange: (isFavorited: boolean) => Promise<void>,
  processing: boolean
  neverShowActionButtons: boolean;
}

export default function JobAltMainHeader(props: Props) {
    
    return (
        <div data-testid='job-alt-main-header'>
          <div className="left-justified">
            <div className="job-alt-header-title">{props.jobDetailsModel.profession} ({props.jobDetailsModel.specialty}) - {props.jobDetailsModel.facility.city},{props.jobDetailsModel.facility.state}
              {props.jobDetailsModel.grade === 1 && <div><img className="detail-icon" data-testid="icon-flame" src={flame_icon} alt="featured"></img></div>}
            </div>
          </div>
          <div className="right-justified">
            {!isArchivedJob(props.jobDetailsModel) && !props.neverShowActionButtons && 
            <>
              <AddToFavoriteButton jobDetails={props.jobDetailsModel} showLogin={props.showLoginDialog} postFavoriteChange={props.postFavoriteChange}></AddToFavoriteButton>
              <StartApplicationButton processing={props.processing} showStartApplicationModal={props.showStartApplicationModal} jobDetails={props.jobDetailsModel}/>
            </>
            }
          </div>
        </div>
    );
}

