import "./policies-and-procedures.scss"
import { useEffect, useState } from "react";
import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import  pdf_icon  from "../../src/assets/pdf_icon.png"
import { useError } from "../utilities/context/useError";
import TPCoreAPIDocuments from "../tp-core-api-common/TPCoreAPIDocuments";
import LoadingOverlay from "../loading-overlay/LoadingOverlay";
import { FileContentType, FileDownloads } from '../common/FileDownloads';


export default function PoliciesAndProcedures() {
    const isMobile = window.matchMedia("(max-width: 1249px)").matches;
    const { setError } = useError()
    const [isLoading, setIsLoading] = useState<boolean>(false);


    async function openFile(fileName: string){
        setIsLoading(true);
        try{
            const myData = await TPCoreAPIDocuments.getPoliciesAndProceduresDocument(fileName);

            const d = new FileDownloads();
            d.displayFile(myData,`${fileName}.pdf`,FileContentType.PDF);
            
            setIsLoading(false);
        }
        catch{
            setError(new Error("Something went wrong when opening the file."))
            setIsLoading(false);
        }
    }


    return(
        <>
            {isLoading &&
                <LoadingOverlay displayText="Loading Document"></LoadingOverlay>
            }
            <div id="PoliciesAndProcedures">
                <GenericPageHeader title="Policies and Procedures"></GenericPageHeader>
                <div className="page-container">

                    <div className="document-display-section">
                        <div className="sub-title"> Access Policies and Procedures</div>
                        <div className="all-documents-container">

                            <div className="document-container clickable" data-testid="clickable-document-container" onClick={() => openFile("Grievance")} >
                                <img src={pdf_icon} alt="document" className="pdf-icon"></img>
                                <div>Grievance Policy</div>
                            </div>

                            <div className="document-container clickable" data-testid="clickable-document-container" onClick={() => openFile("Handbook")} >
                                <img src={pdf_icon} alt="document" className="pdf-icon"></img>
                                <div>TRS Handbook</div>
                            </div>

                            <div className="document-container clickable" data-testid="clickable-document-container" onClick={() => window.open("https://www.canva.com/design/DAGLr3toaDU/ZW3Z3pJLjJ7rTtn2tK_37A/view")} >
                                <img src={pdf_icon} alt="document" className="pdf-icon"></img>
                                <div>Workplace Posters</div>
                            </div>
                        </div>
                        </div>
                </div>
            </div>

        </>
    )
}