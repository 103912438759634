import { useEffect, useState } from "react";
import { NavigationSteps } from "../../tp-core-types/NavigationSteps";
import NavigationHeader from "../navigation-header/NavigationHeader";
import PreviousNextButtonHeader from "../previous-next-button-header/PreviousNextButtonHeader";
import "./Identification.scss";
import DatePickerWrapper from "../../date-picker-wrapper/DatePickerWrapper";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import { HcpIdentification, HcpIdentificationResult } from "../../tp-core-types/HcpIdentification";
import ErrorMesage from "../../error-message/inlilne-error-message/ErrorMessage";

export default function Identification() {
    const today = new Date();

    const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
    const [socialSecurityNumber, setSocialSecurityNumber] = useState<string>("");
    const [dateOfBirth, setDateOfBirth] = useState<Date | null | undefined>(null);
    const [showValidationMessage, setShowValidationMessage] = useState<boolean>(false);
    const [hasSocialSecurityNumber, setHasSocialSecurityNumber] = useState<boolean>(false);
    
    const ssnPlaceholder = "000000000";

    function isValidSocialSecurityNumber()
    {
        const ssn = parseInt(socialSecurityNumber)
        return socialSecurityNumber !== ""
            && ssn !== null 
            && ssn !== undefined
            && !isNaN(ssn) 
            && socialSecurityNumber.length === 9
    }

    function isValidDate()
    {
        return (dateOfBirth === null || dateOfBirth === undefined) ? false : dateOfBirth < new Date();
    }

    async function handleSave() {
        if(isFormComplete) {
            const identification: HcpIdentification = {
                SocialSecurityNumber: socialSecurityNumber !== ssnPlaceholder ? socialSecurityNumber : "",
                DateOfBirth: dateOfBirth as Date
            }
            await TPCoreAPI.postHcpIdentification(identification)
        }
    }

    function stripNonNumbers(e: any) {
        if(e.target.value === ssnPlaceholder)
        {
            e.target.value = "";
        }

        const regex = new RegExp(/^\d+$/);
        if(regex.test(e.target.value) || e.target.value === ""){
            setSocialSecurityNumber(e.target.value);
        }
    }

    function ssnFocus(e: any) {
        if(hasSocialSecurityNumber && e.target.value == ssnPlaceholder)
        {
            e.target.value = "";
            setSocialSecurityNumber(e.target.value);
            setShowValidationMessage(false);
        }
        e.target.type = 'text';
    }

    function ssnBlur(e: any) {
        e.target.type = 'password';
        !isValidSocialSecurityNumber() 
            ? setShowValidationMessage(true) 
            : setShowValidationMessage(false);
        if (hasSocialSecurityNumber && !e.target.value)
        {
            setSocialSecurityNumber(ssnPlaceholder);
            e.target.value = ssnPlaceholder;
            setShowValidationMessage(false);
        }
    
    }

    async function getIdentification() {
        const identificationResponce: HcpIdentificationResult = await TPCoreAPI.getIdentification();

        if(identificationResponce)
        {

            if(identificationResponce.hasSocialSecurityNumber)
            {
                setHasSocialSecurityNumber(identificationResponce.hasSocialSecurityNumber);
            }

            if (identificationResponce.hasSocialSecurityNumber)
            {

                setSocialSecurityNumber(ssnPlaceholder);
            }
            

            if( identificationResponce.dateOfBirth )
            {
                const dob = new Date(identificationResponce.dateOfBirth);
                setDateOfBirth(dob);
            }

        }
    }

    useEffect(() =>{
        if(isValidSocialSecurityNumber() && isValidDate())
        {
            setIsFormComplete(true);
        }
        else{
            setIsFormComplete(false);
        }

    },[socialSecurityNumber, dateOfBirth])

    useEffect(() => {
        getIdentification();
    }, [])

    return (
        <>
            <div id="identification-info">
                <NavigationHeader selectedStep={NavigationSteps.Identification}  showNavigationBar={true} />
                <div className="application-header">
                    <PreviousNextButtonHeader
                        selectedStep={NavigationSteps.Identification}
                        isFormComplete={isFormComplete}
                        callbackOnSave={() => handleSave()}
                    />
                    <div className="title">
                        Identification
                    </div>
                </div>
                <div className="application-page-container" data-testid="identification-info-page-container">
                    <div className="form-container">
                        <div className="social-security-container">
                            <label htmlFor="social-security-input">Social Security Number*</label>
                            <input id="social-security-input" className="social-security-input" pattern="[0-9]*" type="password" inputMode="numeric" maxLength={9} value={socialSecurityNumber} onChange={stripNonNumbers} onFocus={ssnFocus} onBlur={ssnBlur}></input>
                            { hasSocialSecurityNumber &&
                            <div>We already have your SSN on file</div>
                            }
                            { showValidationMessage &&
                                <ErrorMesage message="Please enter a 9 digit social security number"></ErrorMesage>
                            }
                            <div className="social-security-text">
                                <div className="why-question-text">Why do we need this?</div>
                                <div>We use your social security number when running background checks.</div>
                            </div>
                        </div>
                        <div className="dob-container">
                            <label htmlFor="dob-picker">Date of Birth*</label>
                            <div className="dob-input">
                                <DatePickerWrapper 
                                    inputId="dob-picker" 
                                    value={dateOfBirth} 
                                    onChange={(event) => setDateOfBirth(event.target.value)}
                                    maxDate={today}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}