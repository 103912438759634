import { createContext } from "react";

export type Discipline = {
    id :number,
    isAllied: boolean,
    name: string,
    webOrder: number,
}

export type UserInfo = {
    firstName: string,
    lastName: string,
    applicationComplete: boolean,
    eligibleForSubmission: boolean,
    discipline: Discipline,
}

export type UserInfoModel = {
    userInfo: UserInfo,
    token: string,
    getAndSetToken: ()=>void,
    subscribeForLogout: (callback: () => void) => void,
    unsubscribeForLogout: (callback: () => void) => void,
    refreshUserInfo: () => void,
}

const defaultValue: UserInfoModel = {
    userInfo: {
        firstName: "", lastName: "", applicationComplete: false, eligibleForSubmission: false, discipline: {
            id: 0,
            isAllied: false,
            name: "",
            webOrder: 0
        }
    },
    token: '',
    getAndSetToken: function (): void {return},
    subscribeForLogout: function (callback): void {return},
    unsubscribeForLogout: function (callback): void {return},
    refreshUserInfo: function (): void {return}
}

const UserInfoContext = createContext(defaultValue);

export default UserInfoContext;


