import { TimesheetShiftData } from "../tp-core-types/Timesheet"
import "../Timesheet/TimesheetShift.scss";
import displayAsDate from "../utilities/displayAsDate";
import { ChangeEvent, useEffect, useState } from "react";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { TimesheetShiftType } from "../tp-core-types/TimesheetShiftType";
import DatePickerWrapper from "../date-picker-wrapper/DatePickerWrapper";
import displayDateAsTime from "../utilities/displayDateAsTime";
import { ScreenResolutions } from "../common/ScreenResolutions";
import LoadingOverlay from "../loading-overlay/LoadingOverlay";
import TrashCan from "../assets/trash-can.svg";
import DisabledTrashCan from "../assets/disabled-trash-can.svg";

type Props = {
    shiftData: TimesheetShiftData,
    payPeriodStartDate: Date,
    payPeriodEndDate: Date, 
    payPeriodLocked: boolean,    
    saveCompletedCallback: (v:boolean) => void,
    deleteCompletedCallback: (v:boolean) => void,
}

export default function TimesheetShift(props: Props){
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [shiftDate, setShiftDate] = useState<Date | null>(props.shiftData.shiftDate);
    const [shiftStart, setShiftStart] = useState<Date | null>(props.shiftData.startTime);
    const [shiftEnd, setShiftEnd] = useState<Date | null>(props.shiftData.endTime);
    const [shiftLunch, setShiftLunch] = useState<number | null>(props.shiftData.lunch);
    const [shiftTypes, setShiftTypes] = useState<TimesheetShiftType[]>([]);
    const [shiftType, setShiftType] = useState<TimesheetShiftType>();
    
    const [buttonSaveDisabled, setButtonSaveDisabled] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [deleted, setButtonDeleteVisible] =  useState(false)
    
    const isMobile = window.matchMedia("(max-width: " + ScreenResolutions.MobileResolution.toString() + "px)").matches;
    
    const getShiftTypes = async () => {
        const shiftTypesReturn = await TPCoreAPI.getTimesheetShiftTypes(props.shiftData.timesheetId);
        setShiftTypes(shiftTypesReturn);
    }

    useEffect(() => {
        getShiftTypes();
        if(props.shiftData.shiftId === null)
            setIsEditing(true);
    }, [])

    useEffect(() => {
        if(props.shiftData.shiftTypeId !== 0){
            setShiftType(shiftTypes.find(st => st.shiftTypeId === props.shiftData.shiftTypeId))
        }
        else{
            setShiftType(shiftTypes[0])
        }
    }, [shiftTypes])

    useEffect(() => {
        if(shiftDate !== null 
            && (shiftType?.includeShiftTimes === false || (shiftStart !== null && shiftEnd !== null))
            && (shiftType?.includeLunch === false || shiftLunch !== null)
        ) {
            setButtonSaveDisabled(false);
        }
        else{
            setButtonSaveDisabled(true);
        }
    }, [shiftType, shiftDate, shiftStart, shiftEnd, shiftLunch])

    const timeToDate = (event:any) => {
        const hourMin = (event.target.value)
        const hourMinSplit = hourMin.split(':')
        const returnVal = new Date(1900, 0, 1, hourMinSplit[0], hourMinSplit[1]);
        return returnVal;
    }

    function selectShiftType(event: ChangeEvent<HTMLSelectElement>) {
        const typeId: number = parseInt(event.target.value);
        const shiftType: TimesheetShiftType | undefined = shiftTypes.find((st) => st.shiftTypeId === typeId)
        if (shiftType !== undefined)
        {
            setShiftType(shiftType);
        }
    }

    const handleShiftLunchChange = (event: any) => {
        const inputValue: string = event.target.value;
    
        if( inputValue !== "" || inputValue !== null || inputValue !== undefined)
        {
            // Allow only numbers and decimal points
            const numericValue = inputValue.replace(/[^0-9]/g, '');
            const lunchMinutes = numericValue !== '' ? parseInt(numericValue) : null;
            setShiftLunch(lunchMinutes);
        }
        else
        {
            setShiftLunch(null);
        }
      };

    async function handleSave() {
        setButtonSaveDisabled(true);
        setSaving(true);          
        props.saveCompletedCallback(false);
        const returnTimesheetShift: TimesheetShiftData = {
            shiftId: null,
            shiftDate: shiftDate,
            shiftTypeId: shiftType!.shiftTypeId,
            startTime: shiftStart ?? new Date(1900, 0, 1),
            endTime: shiftEnd ?? new Date(1900, 0, 1),
            lunch: shiftLunch ?? 0,
            timesheetId: props.shiftData.timesheetId,
            unit: ""
        }
         
        await TPCoreAPI.saveTimesheetShift(returnTimesheetShift)
        props.saveCompletedCallback(true);
        setSaving(false);
        setIsEditing(false);
    }

    async  function deleteTimesheetShiftEntry()
    {
        props.deleteCompletedCallback(false);
        setButtonSaveDisabled(true);
        if(props.shiftData.shiftId != null){
            await TPCoreAPI.deleteTimesheetShift(props.shiftData.shiftId);
        }

        setButtonSaveDisabled(true); 
        props.deleteCompletedCallback(true);
    } 

 
    return (
        <>
        {  shiftTypes.length > 0 &&
            <>
            { !isEditing &&
            <div id="TimesheetShift"> 
                <div className="TimesheetShift" data-testid="timesheetShift-display">
                    <div className="shift-item">Date: <span className="item-value">{displayAsDate(shiftDate)}</span></div>
                    <div className="shift-item">Shift Type: <span className="item-value">{shiftType?.name}</span></div>
                    <div className="shift-item">Start Time: <span className="item-value">{displayDateAsTime(shiftStart)}</span></div>
                    <div className="shift-item">End Time: <span className="item-value">{displayDateAsTime(shiftEnd)}</span></div>
                    <div className="shift-item">Lunch: <span className="item-value">{shiftLunch} minutes</span></div>
                </div>
                {  !props.payPeriodLocked && !deleted &&
                <div className="edit-buttons-container">
                     <div className="delete-record-button-container">
                        <button data-testid="delete-button" className="delete-record-button" onClick={() => deleteTimesheetShiftEntry()} 
                        //disabled={!props.isDeleteButtonEnabled}
                        >
                            {/* { !props.isDeleteButtonEnabled  && <img src={DisabledTrashCan} alt="trash can"/> }
                            { props.isDeleteButtonEnabled && <img src={TrashCan} alt="trash can"/> } */}
                            <div className="delete-record-text">Delete Entry</div>
                        </button>
                    </div> 
                </div>
                }
            </div>
            }
            {!isMobile && isEditing &&        
            <div id="TimesheetShift" data-testid="timesheetShiftForm"> 
                {saving && <LoadingOverlay/>}
                <div className="TimesheetShiftEditContainer">
                    <div className="TimesheetShiftEdit" data-testid="timesheetShift-edit">
                        <div className="shift-item-times-container"  > 
                            <div className="shift-item">
                                <label htmlFor="shift-date">Date</label>
                                <DatePickerWrapper
                                    inputId="shift-date"                             
                                    showButtonBar={false}
                                    onChange={(event)=>{event.value && setShiftDate(event.value)}}
                                    value={shiftDate}
                                    minDate={props.payPeriodStartDate} 
                                    maxDate={props.payPeriodEndDate}                             
                                />
                            </div>
                            
                            <div className="shift-item">
                                <label htmlFor="shift-type">Shift Type</label>
                                <select id="shift-type" className="item-value" value={shiftType?.shiftTypeId} onChange={selectShiftType}>
                                    {shiftTypes.map((t: TimesheetShiftType) => { return <option value={t.shiftTypeId}>{t.name}</option> })}
                                    </select>
                            </div> 
                        </div>
                        <div className="shift-item-times-container"> 
                            {shiftType?.includeShiftTimes &&
                            <>
                                <div className="shift-item">
                                    <label htmlFor="shift-start">Shift Start Time</label>
                                    <input id="shift-start" type='time' className="item-value" onChange={event => setShiftStart(timeToDate(event))} ></input>
                                </div>
                                <div className="shift-item">
                                    <label htmlFor="shift-end">Shift End Time</label>
                                    <input id="shift-end" type='time' className="item-value" onChange={event => setShiftEnd(timeToDate(event))}></input>
                                </div>
                            </>
                            }
                            {shiftType?.includeLunch && 
                            <>
                                <div className="shift-item">
                                    <label htmlFor="shift-lunch">Lunch</label>                                
                                    <div className="shift-lunch-container">
                                    <input id="shift-lunch" className="item-value" type="text" value={shiftLunch !== null ? shiftLunch.toString() : ""} 
                                    onChange={handleShiftLunchChange}/><span>minutes</span>
                                    </div>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                    <div className="edit-buttons-container">
                    <div className="delete-record-button-container">
                        <button data-testid="delete-button" className="delete-record-button" onClick={() => deleteTimesheetShiftEntry()} >
                            {/* { !props.isDeleteButtonEnabled  && <img src={DisabledTrashCan} alt="trash can"/> }
                            { props.isDeleteButtonEnabled && <img src={TrashCan} alt="trash can"/> } */}
                            <div className="delete-record-text">Delete Entry</div>
                        </button>
                    </div> 
                    <div className="save-record-button-container">
                            <button className="save-record-button" disabled={buttonSaveDisabled} onClick={handleSave}>Save Time</button>
                        </div>
                    </div> 


                </div>
            </div>
            } 
            {isMobile && isEditing && 
            <div id="TimesheetShift"  data-testid="timesheetShiftForm"> 
                {saving && <LoadingOverlay/>}  
                <div className="TimesheetShiftEditContainer">
                    <div className="TimesheetShiftEdit" data-testid="timesheetShift-edit"> 
                        <div className="shift-item">
                            <label htmlFor="shift-date">Date*</label>
                            <DatePickerWrapper
                                inputId="shift-date" 
                                showButtonBar={false}
                                onChange={(event)=>{event.value && setShiftDate(event.value)}}
                                value={shiftDate} 
                                minDate={props.payPeriodStartDate} 
                                maxDate={props.payPeriodEndDate}
                            />
                        </div>
                        <div className="shift-item">
                            <label htmlFor="shift-type">Shift Type* </label>
                            <select id="shift-type" className="item-value" value={shiftType?.shiftTypeId} onChange={selectShiftType}>
                                {shiftTypes.map((t: TimesheetShiftType) => { return <option value={t.shiftTypeId}>{t.name}</option> })}
                            </select>
                        </div>
                        { shiftType?.includeShiftTimes && 
                            <>
                                <div className="shift-item">
                                    <label htmlFor="shift-start">Start Time*</label>
                                    <input id="shift-start" type='time' className="item-value" onChange={event => setShiftStart(timeToDate(event))}></input>
                                </div>
                                <div className="shift-item">
                                    <label htmlFor="shift-end">End Time*</label>
                                    <input id="shift-end" type='time' className="item-value" onChange={event => setShiftEnd(timeToDate(event))}></input>
                                </div>
                            </>
                        }
                        {shiftType?.includeLunch &&
                            <div className="shift-item">
                                <label htmlFor="shift-lunch">Lunch</label>                                
                                    <div className="shift-lunch-container">
                                    <input id="shift-lunch" className="item-value" type="text" value={shiftLunch !== null ? shiftLunch.toString() : ""} 
                                    onChange={handleShiftLunchChange}/><span>minutes</span>
                                    </div>
                            </div>
                        }
                    </div>
                    <div className="edit-buttons-container">                        
                        <div className="save-record-button-container">
                            <button className="save-record-button" disabled={buttonSaveDisabled} onClick={handleSave}>Save Entry</button>
                        </div>
                    </div>
                </div>
            </div>
            }        
            </>
        }
        </>
    )
}