import { useEffect, useState } from "react"
import "./LoginModal.css"
import LoginForm from "./login-form/LoginForm"
import SignUpForm from "./signup-form/SignUpForm"
import xIcon from "../assets/x.png";
import ForgotPasswordModal from "../forgot-password-modal/ForgotPasswordModal"

type Props = {
    isLoginForm: boolean
    isVisible: boolean
    setVisibility: (value: boolean) => void
    successfulLogin: () => Promise<void>
    headerText: string;
    canBeDismissed: boolean
}

export default function LoginModal(props: Props){
    let headerClickAccumulatorCount = 0;
    let headerClickAccumulatorDate = new Date();
    
    const [isLoginForm, setIsLogin] = useState(props.isLoginForm);
    const [showForgot, setShowForgot] = useState(false);
    const [headerText, setHeaderText] = useState(props.headerText)

    useEffect(()=>{
        setHeaderText(props.headerText)
    }, [props.headerText])

    useEffect(()=>{
        setIsLogin(props.isLoginForm)
    }, [props.isLoginForm])

    const showLoginForm = (showLogin: boolean)=>{
        setIsLogin(showLogin);
    }
    const handleShowForgotPassword = (showForgot: boolean) => {
        setShowForgot(showForgot);
        if(showForgot) {
            setHeaderText("Reset password");
        } else{
            setHeaderText(props.headerText);
        }
    }

    const handleDismiss = () => {
        if (props.canBeDismissed) {
            props.setVisibility(false)
        }
    }

    function headerClick() {
        const now = new Date();

        const elapsedMilli = now.getTime() - headerClickAccumulatorDate.getTime();

        if(elapsedMilli > 10000) {
            headerClickAccumulatorDate = now;
            headerClickAccumulatorCount = 0;
        }

        ++headerClickAccumulatorCount;

        if(headerClickAccumulatorCount >= 10) {
            if(window && (window as any as {ReactNativeWebView: any}).ReactNativeWebView) {            
                const message = {
                    type: 'envSwitch'
                };
                (window as any as {ReactNativeWebView: any}).ReactNativeWebView.postMessage(JSON.stringify(message));
            }
        }

    }

    return(
        <>
            {props.isVisible &&
                <div data-testid="login-modal" className={isLoginForm ? "login-modal-background login-selected" : "login-modal-background signup-selected" } onClick={handleDismiss}>
                    <div className="login-container" data-testid="login-container" onClick={(e) => e.stopPropagation()}>
                        <div className="login-header" onClick={headerClick} data-testid="login-header">{ headerText }
                        
                        {props.canBeDismissed && <img
                            alt="Close Login"
                            className="x-button"
                            src={xIcon}
                            onClick={() => {
                            props.setVisibility(false);
                            }}
                        />}
                        </div>
                        {!showForgot && 
                            <div className="login-body">
                                <div className="login-form-header">
                                    <div className="login-form-header-item">
                                        <div className={ isLoginForm ? "login-form-header-text selected-form-header-item": "login-form-header-text"}>
                                            <div onClick={() => setIsLogin(true)} className="login-header-button" data-testid="login-header-button">Login</div>
                                        </div>
                                        <div className={ isLoginForm ? "login-form-header-text": "login-form-header-text selected-form-header-item"}>
                                            <div onClick={() => setIsLogin(false)} className="signup-header-button" data-testid="signup-header-button">Sign Up</div>
                                        </div>
                                    </div>
                                </div>
                                { isLoginForm &&
                                    <LoginForm showForgotPasword={handleShowForgotPassword} successfulLogin={props.successfulLogin} showLogin={showLoginForm} />
                                }
                                { !isLoginForm &&
                                    <SignUpForm successfulLogin={props.successfulLogin} setVisibility={props.setVisibility}></SignUpForm>
                                }
                            </div>
                        }
                        {showForgot &&
                        <div className="login-body">
                            <ForgotPasswordModal showForgotPasswordModal={handleShowForgotPassword}></ForgotPasswordModal>
                        </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}